<template>
    <div>
        <b-form-input type="text" v-model="input_text" :placeholder="placeholder" :required="required"></b-form-input>
    </div>
</template>

<script type="text/javascript">
	import Vue from 'vue'
    import Navigation from '@/mixins/Navigation.js'

	export default {
		name: "TextInput",
        mixins: [Navigation],
        props:[
            'placeholder',
            'q_id',
            'answer_given',
            'required',
            'z_id',
            'content'
        ],
        data() {
            return {
                input_text: '',
                events_tab: {
                    'inputs::resetChecked': this.resetCheck
                }
            }
        },
        mounted() {
            this.init_component()
        },
        methods: {
            init_component() {
                this.input_text = this.content
            },
            resetCheck() {
                this.input_text = ''
            }
        },
        watch: {
            'input_text' (val) {
                this.$emit('update:answer', { key: this.q_id, val: val, type: 'TextInput' })
                this.$emit('change', {val: val, key: this.z_id})
            }
        }
    }
</script>
